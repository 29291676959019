<!-- Default green -->
<ng-container *ngIf="variant === LOADING_STYLE.DEFAULT">
    <div class="default-spinner"></div>
</ng-container>

<!-- Gray spinner -->
<ng-container *ngIf="variant === LOADING_STYLE.CIRCLE_GRAY">
    <div class="gray-spinner"></div>
</ng-container>


<!-- Three dots gray -->
<ng-container *ngIf="variant === LOADING_STYLE.TWO_DOTS_RED">
    <div class="two-dots-spinner"></div>
</ng-container>