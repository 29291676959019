import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsLogoComponent } from './fs-logo/fs-logo.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { FsButtonComponent } from './fs-button/fs-button.component';
import { FsInputComponent } from './fs-input/fs-input.component';
import { FsMenuSimpleComponent } from './fs-menu-simple/fs-menu-simple.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FsLinkComponent } from './fs-link/fs-link.component';
import { FsCheckboxComponent } from './fs-checkbox/fs-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { SharedModelsModule } from '@web-foodis/shared-models';
import { NavLanguageSelectorComponent } from './nav-language-selector/nav-language-selector.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FsTextareaComponent } from './fs-textarea/fs-textarea.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FsGenericModalComponent } from './fs-generic-modal/fs-generic-modal.component';
import { MatDialogModule } from "@angular/material/dialog";
import { ModalService } from "./modal-service/modal-service.service";
import { FsMenuAdvancedComponent } from "./fs-menu-advanced/fs-menu-advanced.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FsRadioButtonComponent } from './fs-radio-button/fs-radio-button.component';
import { FsChipListComponent } from './fs-chip-list/fs-chip-list.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { BaseAddressEditorComponent } from './fs-base-address-editor/fs-base-address-editor.component';
import { FsMapLiteComponent } from './fs-map-lite/fs-map-lite.component';
import { MatSliderModule } from '@angular/material/slider';
import { FsSliderComponent } from './fs-slider/fs-slider.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FsLoadingSpinnerComponent } from './fs-loading-spinner/fs-loading-spinner.component';
import { FsDebugBadgeComponent } from './fs-debug-badge/fs-debug-badge.component';
import { FsDeliveryAddressComponent } from './fs-delivery-address/fs-delivery-address.component';

@NgModule({
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatRadioModule,
    MatTabsModule,
    MatSliderModule,
    DragDropModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatExpansionModule,
    GoogleMapsModule,
    SharedModelsModule,
  ],
  exports: [
    FsLogoComponent,
    FsButtonComponent,
    FsRadioButtonComponent,
    FsChipListComponent,
    FsInputComponent,
    FsMenuSimpleComponent,
    FsMenuAdvancedComponent,
    FsLinkComponent,
    FsCheckboxComponent,
    FsGenericModalComponent,
    FsTextareaComponent,
    NavLanguageSelectorComponent,
    BaseAddressEditorComponent,
    FsMapLiteComponent,
    FsSliderComponent,
    FsLoadingSpinnerComponent,
    FsDebugBadgeComponent,
    FsDeliveryAddressComponent,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatSliderModule,
    DragDropModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatExpansionModule,
    GoogleMapsModule,
    SharedModelsModule,
  ],
  declarations: [
    FsLogoComponent,
    FsButtonComponent,
    FsRadioButtonComponent,
    FsChipListComponent,
    FsTextareaComponent,
    FsInputComponent,
    FsMenuSimpleComponent,
    FsMenuAdvancedComponent,
    FsLinkComponent,
    FsCheckboxComponent,
    NavLanguageSelectorComponent,
    FsGenericModalComponent,
    BaseAddressEditorComponent,
    FsMapLiteComponent,
    FsSliderComponent,
    FsLoadingSpinnerComponent,
    FsDebugBadgeComponent,
    FsDeliveryAddressComponent
  ],
  providers: [
      ModalService
  ]
})
export class SharedUiModule {}
