import { Component, Input, OnInit } from '@angular/core';
import { AddressResponse } from 'libs/shared-models/src/lib/address-response';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fs-delivery-address',
  templateUrl: './fs-delivery-address.component.html',
  styleUrls: ['./fs-delivery-address.component.scss'],
})
export class FsDeliveryAddressComponent implements OnInit {

  public userAddress$: BehaviorSubject<AddressResponse | null> = new BehaviorSubject<AddressResponse | null>(null);

  @Input() usage: string = "default"; // ["default", "user-address-page", "order-page"]
  @Input() set inputAddress(address: AddressResponse | undefined | null) {
    if (address) {
      this.userAddress$.next(address);
    }
  }

  constructor(
  ) {
  }

  public ngOnInit(): void {  
  }

}
