<div class="address-text">
    <!-- Icon & title -->
    <div class="address-title">
        <div class="address-delivery-icon">
            <img [src]="'./libs/shared-ui/assets/icons/delivery-type-bicycle.svg'"/>
        </div>
        <!-- {{ 'checkout_delivery_address' | translate }} -->
        Customer delivery address
    </div>

    <!-- Street -->
    <div class="address-street pb-1">
        <!-- <div class="address-label">{{ 'checkout_delivery_street' | translate  }}: </div> -->
        <div class="address-label">Street: </div>
        <div class="address-content bold">
            {{ 
                (userAddress$ | async)?.streetName + " " +
                (userAddress$ | async)?.streetNumber
            }}
        </div>
    </div>

    <div class="flex flex-row pb-1">
        <!-- Door -->
        <div class="flex flex-row mr-5">
            <!-- <div class="address-label">{{ 'checkout_delivery_door' | translate  }}: </div> -->
            <div class="address-label">Door: </div>
            <div class="address-content bold">{{ (userAddress$ | async)?.doorNumber || '-' }}</div>
        </div>
        <!-- Floor -->
        <div class="flex flex-row">
            <!-- <div class="address-label">{{ 'checkout_delivery_floor' | translate  }}: </div> -->
            <div class="address-label">Floor: </div>
            <div class="address-content">{{ (userAddress$ | async)?.floor || '-' }}</div>
        </div>
    </div>

    <!-- Entrance -->
    <div class="flex flex-row pb-1">
        <!-- <div class="address-label">{{ 'checkout_delivery_entrance' | translate  }}: </div> -->
        <div class="address-label">Entrance: </div>
        <div class="address-content">{{ (userAddress$ | async)?.entrance || '-' }}</div>
    </div>

    <!-- Specific details -->
    <div class="flex flex-row pb-1">
        <!-- <div class="address-label">{{ 'checkout_delivery_specific' | translate  }}: </div> -->
        <div class="address-label">Specific details: </div>
        <div class="address-content">{{ (userAddress$ | async)?.additionalInfo || '-' }}</div>
    </div>

    <!-- Postal code, city -->
    <div class="flex flex-row pt-3">
        <div class="address-content">
            {{ (userAddress$ | async)?.postalCode }}
            {{ (userAddress$ | async)?.district }},
            {{ (userAddress$ | async)?.city }}
        </div>
    </div>
    
</div>